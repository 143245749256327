h1,
.h1 {
  @apply text-[30px] xl:text-[48px] leading-tight font-bold;
}

h2,
.h2 {
  @apply text-[28px] lg:text-[44px] font-bold leading-tight;
}

h3,
.h3 {
  @apply text-[26px] xl:text-[32px] leading-normal font-bold;
}

h4,
.h4 {
  @apply font-bold leading-normal text-[24px] xl:text-[28px];
}

h5,
.h5 {
  @apply font-bold text-[20px] leading-normal;
}

h6,
.h6 {
  @apply font-bold text-[16px] leading-normal;
}

p {
  @apply text-[16px] font-medium leading-normal;
}

p + p {
  @apply mt-5;
}

strong {
  @apply text-[16px] font-bold leading-normal;
}

small {
  @apply text-[11px] font-normal leading-normal;
}

.page-content {
  h1,
  .h1 {
    @apply my-4;
  }
  h2,
  .h2 {
    @apply my-4;
  }
  h3,
  .h3 {
    @apply my-4;
  }
  h4,
  .h4 {
    @apply my-4;
  }
  h5,
  .h5 {
    @apply my-4;
  }
  h6,
  .h6 {
    @apply my-4;
  }
}
