*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Montserrat, Roboto, Helvetica Neue, Arial Nova, Nimbus Sans, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

input:where(:not([type])) {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input:where(:not([type])):focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 320px) {
  .container {
    max-width: 320px;
  }
}

@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1740px) {
  .container {
    max-width: 1740px;
  }
}

.debug-screens:before {
  z-index: 2147483647;
  color: #fff;
  content: "screen: _";
  background-color: #000;
  padding: .333333em .5em;
  font-family: sans-serif;
  font-size: 12px;
  line-height: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 0 1px #fff;
}

@media (min-width: 320px) {
  .debug-screens:before {
    content: "screen: xs";
  }
}

@media (min-width: 480px) {
  .debug-screens:before {
    content: "screen: s";
  }
}

@media (min-width: 576px) {
  .debug-screens:before {
    content: "screen: sm";
  }
}

@media (min-width: 768px) {
  .debug-screens:before {
    content: "screen: md";
  }
}

@media (min-width: 992px) {
  .debug-screens:before {
    content: "screen: lg";
  }
}

@media (min-width: 1440px) {
  .debug-screens:before {
    content: "screen: xl";
    content: "screen: 2xl";
  }
}

@media (min-width: 1740px) {
  .debug-screens:before {
    content: "screen: 3xl";
  }
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

@media (min-width: 320px) {
  .container {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
    padding: 0;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1896px;
  }
}

.rwd-scroll-table {
  width: calc(100vw - 32px);
  overflow-x: scroll;
}

@media (min-width: 992px) {
  .rwd-scroll-table {
    width: 100%;
    overflow: auto;
  }
}

.rwd-table {
  table-layout: fixed;
  -webkit-user-select: none;
  user-select: none;
  width: 500px;
  max-width: 500px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 12px;
  position: relative;
}

@media (min-width: 576px) {
  .rwd-table {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .rwd-table {
    table-layout: auto;
    width: 100%;
    max-width: 100%;
  }
}

.rwd-table caption {
  height: 1px;
  overflow: hidden;
}

.rwd-table tr.table-header th {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: left;
  width: 110px;
  max-width: 110px;
  padding: .5rem;
  position: sticky;
  left: 0;
}

.rwd-table tr.table-header td {
  text-align: center;
  width: 140px;
  padding: .5rem;
}

.rwd-table tr.table-data th {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: left;
  padding: .5rem;
  position: sticky;
  left: 0;
}

.rwd-table tr.table-data td {
  text-align: center;
  padding: .5rem;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.\!static {
  position: static !important;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-\[-83px\] {
  bottom: -83px;
}

.bottom-\[10px\] {
  bottom: 10px;
}

.bottom-\[12px\] {
  bottom: 12px;
}

.bottom-\[20px\] {
  bottom: 20px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.left-\[-1950px\] {
  left: -1950px;
}

.left-\[-20px\] {
  left: -20px;
}

.left-\[-2px\] {
  left: -2px;
}

.left-\[-73px\] {
  left: -73px;
}

.left-\[0\] {
  left: 0;
}

.left-\[10px\] {
  left: 10px;
}

.left-\[14px\] {
  left: 14px;
}

.left-\[20px\] {
  left: 20px;
}

.left-\[4px\] {
  left: 4px;
}

.left-\[80px\] {
  left: 80px;
}

.left-\[calc\(50\%\)\] {
  left: 50%;
}

.left-\[calc\(50\%_-_290px\)\] {
  left: calc(50% - 290px);
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.right-\[-1950px\] {
  right: -1950px;
}

.right-\[-19px\] {
  right: -19px;
}

.right-\[-269px\] {
  right: -269px;
}

.right-\[-73px\] {
  right: -73px;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[78px\] {
  right: 78px;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-16 {
  top: 4rem;
}

.top-4 {
  top: 1rem;
}

.top-\[-100px\] {
  top: -100px;
}

.top-\[-105px\] {
  top: -105px;
}

.top-\[-10px\] {
  top: -10px;
}

.top-\[-12px\] {
  top: -12px;
}

.top-\[-20px\] {
  top: -20px;
}

.top-\[-49px\] {
  top: -49px;
}

.top-\[-60px\] {
  top: -60px;
}

.top-\[0\] {
  top: 0;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[18px\] {
  top: 18px;
}

.top-\[49px\] {
  top: 49px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[60px\] {
  top: 60px;
}

.-z-0 {
  z-index: 0;
}

.-z-10 {
  z-index: -10;
}

.-z-\[9\] {
  z-index: -9;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[0\] {
  z-index: 0;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2\] {
  z-index: 2;
}

.z-\[3\] {
  z-index: 3;
}

.z-\[4\] {
  z-index: 4;
}

.z-\[5\] {
  z-index: 5;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.my-\[3px\] {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[0\] {
  margin-top: 0;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0;
}

.h-6 {
  height: 1.5rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[112px\] {
  height: 112px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[134px\] {
  height: 134px;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[153px\] {
  height: 153px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[170px\] {
  height: 170px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[182px\] {
  height: 182px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[211px\] {
  height: 211px;
}

.h-\[223px\] {
  height: 223px;
}

.h-\[237px\] {
  height: 237px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[267px\] {
  height: 267px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[306px\] {
  height: 306px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[311px\] {
  height: 311px;
}

.h-\[339px\] {
  height: 339px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[374px\] {
  height: 374px;
}

.h-\[39px\] {
  height: 39px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[540px\] {
  height: 540px;
}

.h-\[543px\] {
  height: 543px;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[638px\] {
  height: 638px;
}

.h-\[64px\] {
  height: 64px;
}

.h-\[66px\] {
  height: 66px;
}

.h-\[83px\] {
  height: 83px;
}

.h-\[85px\] {
  height: 85px;
}

.h-\[9px\] {
  height: 9px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.\!max-h-\[730px\] {
  max-height: 730px !important;
}

.max-h-0 {
  max-height: 0;
}

.max-h-\[339px\] {
  max-height: 339px;
}

.max-h-\[413px\] {
  max-height: 413px;
}

.max-h-screen {
  max-height: 100vh;
}

.\!min-h-\[730px\] {
  min-height: 730px !important;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[120px\] {
  min-height: 120px;
}

.min-h-\[190px\] {
  min-height: 190px;
}

.min-h-\[194px\] {
  min-height: 194px;
}

.min-h-\[20px\] {
  min-height: 20px;
}

.min-h-\[36px\] {
  min-height: 36px;
}

.min-h-\[420px\] {
  min-height: 420px;
}

.min-h-\[665px\] {
  min-height: 665px;
}

.min-h-\[72px\] {
  min-height: 72px;
}

.min-h-\[74px\] {
  min-height: 74px;
}

.min-h-\[84px\] {
  min-height: 84px;
}

.min-h-full {
  min-height: 100%;
}

.\!w-\[296px\] {
  width: 296px !important;
}

.\!w-full {
  width: 100% !important;
}

.w-1\/2 {
  width: 50%;
}

.w-11 {
  width: 2.75rem;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[112px\] {
  width: 112px;
}

.w-\[12px\] {
  width: 12px;
}

.w-\[134px\] {
  width: 134px;
}

.w-\[13px\] {
  width: 13px;
}

.w-\[142px\] {
  width: 142px;
}

.w-\[153px\] {
  width: 153px;
}

.w-\[162px\] {
  width: 162px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[178px\] {
  width: 178px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[182px\] {
  width: 182px;
}

.w-\[2000px\] {
  width: 2000px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[225px\] {
  width: 225px;
}

.w-\[227px\] {
  width: 227px;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[249px\] {
  width: 249px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[28px\] {
  width: 28px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[306px\] {
  width: 306px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[311px\] {
  width: 311px;
}

.w-\[34px\] {
  width: 34px;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[39px\] {
  width: 39px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[44px\] {
  width: 44px;
}

.w-\[450px\] {
  width: 450px;
}

.w-\[45px\] {
  width: 45px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[52px\] {
  width: 52px;
}

.w-\[543px\] {
  width: 543px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[638px\] {
  width: 638px;
}

.w-\[64px\] {
  width: 64px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[83px\] {
  width: 83px;
}

.w-\[85px\] {
  width: 85px;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[calc\(100\%_-_24px\)\] {
  width: calc(100% - 24px);
}

.w-\[var\(--width\,0\)\] {
  width: var(--width, 0);
}

.w-full {
  width: 100%;
}

.\!min-w-\[85px\] {
  min-width: 85px !important;
}

.min-w-\[431px\] {
  min-width: 431px;
}

.min-w-\[440px\] {
  min-width: 440px;
}

.min-w-\[450px\] {
  min-width: 450px;
}

.min-w-\[55px\] {
  min-width: 55px;
}

.min-w-full {
  min-width: 100%;
}

.\!max-w-\[85px\] {
  max-width: 85px !important;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[1076px\] {
  max-width: 1076px;
}

.max-w-\[1077px\] {
  max-width: 1077px;
}

.max-w-\[1296px\] {
  max-width: 1296px;
}

.max-w-\[135px\] {
  max-width: 135px;
}

.max-w-\[170px\] {
  max-width: 170px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[290px\] {
  max-width: 290px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[305px\] {
  max-width: 305px;
}

.max-w-\[306px\] {
  max-width: 306px;
}

.max-w-\[313px\] {
  max-width: 313px;
}

.max-w-\[331px\] {
  max-width: 331px;
}

.max-w-\[340px\] {
  max-width: 340px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[380px\] {
  max-width: 380px;
}

.max-w-\[395px\] {
  max-width: 395px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[420px\] {
  max-width: 420px;
}

.max-w-\[430px\] {
  max-width: 430px;
}

.max-w-\[431px\] {
  max-width: 431px;
}

.max-w-\[440px\] {
  max-width: 440px;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.max-w-\[491px\] {
  max-width: 491px;
}

.max-w-\[508px\] {
  max-width: 508px;
}

.max-w-\[520px\] {
  max-width: 520px;
}

.max-w-\[530px\] {
  max-width: 530px;
}

.max-w-\[550px\] {
  max-width: 550px;
}

.max-w-\[55px\] {
  max-width: 55px;
}

.max-w-\[570px\] {
  max-width: 570px;
}

.max-w-\[606px\] {
  max-width: 606px;
}

.max-w-\[612px\] {
  max-width: 612px;
}

.max-w-\[616px\] {
  max-width: 616px;
}

.max-w-\[635px\] {
  max-width: 635px;
}

.max-w-\[636px\] {
  max-width: 636px;
}

.max-w-\[688px\] {
  max-width: 688px;
}

.max-w-\[75\%\] {
  max-width: 75%;
}

.max-w-\[80px\] {
  max-width: 80px;
}

.max-w-\[856px\] {
  max-width: 856px;
}

.max-w-\[905px\] {
  max-width: 905px;
}

.max-w-\[918px\] {
  max-width: 918px;
}

.max-w-\[966px\] {
  max-width: 966px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-none {
  max-width: none;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-full {
  flex-basis: 100%;
}

.table-fixed {
  table-layout: fixed;
}

.-translate-x-1\/2, .-translate-x-\[50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.4\] {
  --tw-scale-x: 1.4;
  --tw-scale-y: 1.4;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-\[2px\] {
  gap: 2px;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-\[\#ccc\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-divide-opacity));
}

.place-self-center {
  place-self: center;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[22px\] {
  border-radius: 22px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 60px;
}

.rounded-md {
  border-radius: 30px;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: 15px;
}

.rounded-xs {
  border-radius: 7px;
}

.rounded-b-\[22px\] {
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}

.rounded-b-lg {
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;
}

.rounded-b-sm {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rounded-l-lg {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.rounded-l-sm {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rounded-r-lg {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.rounded-r-sm {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.rounded-t-\[22px\] {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}

.rounded-t-xs {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.rounded-tr-\[10px\] {
  border-top-right-radius: 10px;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2, .border-\[2px\] {
  border-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#888\] {
  --tw-border-opacity: 1;
  border-color: rgb(136 136 136 / var(--tw-border-opacity));
}

.border-\[\#CCC\] {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.border-\[\#EEE\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));
}

.border-\[\#F6F5F5\] {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 245 / var(--tw-border-opacity));
}

.border-\[\#ccc\] {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.border-\[var\(--color\,black\)\] {
  border-color: var(--color, black);
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue {
  border-color: #0071db;
}

.border-blue-dark {
  border-color: #1664ac;
}

.border-gray {
  border-color: #ccc;
}

.border-gray-light {
  border-color: #f6f5f5;
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgba(0, 255, 153, var(--tw-border-opacity));
}

.border-purple {
  border-color: #f06;
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 0, var(--tw-border-opacity));
}

.border-x-transparent {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.\!bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#888\] {
  --tw-bg-opacity: 1;
  background-color: rgb(136 136 136 / var(--tw-bg-opacity));
}

.bg-\[\#F6F5F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 245 / var(--tw-bg-opacity));
}

.bg-\[\#FF0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 0 / var(--tw-bg-opacity));
}

.bg-accent-blue {
  background-color: #3cf;
}

.bg-accent-blue-light {
  background-color: #33ccff80;
}

.bg-accent-gray {
  background-color: #ccc;
}

.bg-accent-gray-light {
  background-color: #ccc6;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  background-color: #0071db;
}

.bg-blue-dark {
  background-color: #1664ac;
}

.bg-deepblue {
  background-color: #003;
}

.bg-gray {
  background-color: #ccc;
}

.bg-gray-light {
  background-color: #f6f5f5;
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 255, 153, var(--tw-bg-opacity));
}

.bg-green-light {
  background-color: #00ff9980;
}

.bg-orange {
  background-color: #f90;
}

.bg-orange-light {
  background-color: #ff990080;
}

.bg-purple {
  background-color: #f06;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity));
}

.bg-yellow-light {
  background-color: #ff03;
}

.bg-\[url\(\'\/assets\/theme\/icons\/section-bg\.svg\'\)\] {
  background-image: url("section-bg.487a49b6.svg");
}

.bg-none {
  background-image: none;
}

.bg-\[50\%_calc\(100\%_\+_20px\)\] {
  background-position: 50% calc(100% + 20px);
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-\[calc\(50\%_-_68px\)_calc\(50\%\)\] {
  object-position: calc(50% - 68px) calc(50%);
}

.object-bottom {
  object-position: bottom;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-\[53px\] {
  padding-left: 53px;
  padding-right: 53px;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-\[18px\] {
  padding-top: 18px;
  padding-bottom: 18px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[140px\] {
  font-size: 140px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[33px\] {
  font-size: 33px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[44px\] {
  font-size: 44px;
}

.text-\[52px\] {
  font-size: 52px;
}

.\!font-black {
  font-weight: 900 !important;
}

.\!font-bold {
  font-weight: 700 !important;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.leading-\[36px\] {
  line-height: 36px;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[2px\] {
  letter-spacing: 2px;
}

.tracking-wide {
  letter-spacing: .025em;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-\[\#888\] {
  --tw-text-opacity: 1;
  color: rgb(136 136 136 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  color: #0071db;
}

.text-blue-dark {
  color: #1664ac;
}

.text-deepblue {
  color: #003;
}

.text-gray-light {
  color: #f6f5f5;
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(0, 255, 153, var(--tw-text-opacity));
}

.text-purple {
  color: #f06;
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 0, var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.opacity-30 {
  opacity: .3;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-blue {
  --tw-ring-color: #0071db;
}

.ring-blue-dark {
  --tw-ring-color: #1664ac;
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.contain-layout {
  contain: layout;
}

.\[appearance\:textfield\] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.\[hostname\:port\] {
  hostname: port;
}

h1, .h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
}

@media (min-width: 1440px) {
  h1, .h1 {
    font-size: 48px;
  }
}

h2, .h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.25;
}

@media (min-width: 992px) {
  h2, .h2 {
    font-size: 44px;
  }
}

h3, .h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 1440px) {
  h3, .h3 {
    font-size: 32px;
  }
}

h4, .h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 1440px) {
  h4, .h4 {
    font-size: 28px;
  }
}

h5, .h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

h6, .h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

p + p {
  margin-top: 1.25rem;
}

strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

small {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
}

.page-content h1, .page-content .h1, .page-content h2, .page-content .h2, .page-content h3, .page-content .h3, .page-content h4, .page-content .h4, .page-content h5, .page-content .h5, .page-content h6, .page-content .h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.keen-slider:not([data-keen-slider-disabled]) {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  align-content: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
  flex-direction: row-reverse;
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
  flex-wrap: wrap;
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url("montserrat-v25-latin_latin-ext-100.33356c83.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-100.9d4df828.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  src: url("montserrat-v25-latin_latin-ext-100italic.3fab5b16.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-100italic.81383c13.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url("montserrat-v25-latin_latin-ext-200.b3053f20.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-200.bb956d01.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  src: url("montserrat-v25-latin_latin-ext-200italic.3b8a4007.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-200italic.cc47bf6b.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url("montserrat-v25-latin_latin-ext-300.fd148519.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-300.87a45af1.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  src: url("montserrat-v25-latin_latin-ext-300italic.c717581b.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-300italic.e9fa374b.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("montserrat-v25-latin_latin-ext-regular.87cce9af.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-regular.f606aa38.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  src: url("montserrat-v25-latin_latin-ext-italic.e7124978.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-italic.2c4eb0de.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("montserrat-v25-latin_latin-ext-500.35db0446.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-500.d28c17a9.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  src: url("montserrat-v25-latin_latin-ext-500italic.89e9a093.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-500italic.11cc2fb8.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("montserrat-v25-latin_latin-ext-600.abb3b10c.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-600.704e5baf.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  src: url("montserrat-v25-latin_latin-ext-600italic.379ab767.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-600italic.35a6264f.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("montserrat-v25-latin_latin-ext-700.62976001.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-700.1d66e43a.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  src: url("montserrat-v25-latin_latin-ext-700italic.c482a5d8.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-700italic.9f66ff0b.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("montserrat-v25-latin_latin-ext-800.b5ad47b6.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-800.872b2f64.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  src: url("montserrat-v25-latin_latin-ext-800italic.ff81900d.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-800italic.336718f4.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("montserrat-v25-latin_latin-ext-900.80f55bf8.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-900.271bb4f2.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 900;
  src: url("montserrat-v25-latin_latin-ext-900italic.65384fa8.woff2") format("woff2"), url("montserrat-v25-latin_latin-ext-900italic.f4df57d0.ttf") format("truetype");
}

.card-sticky {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.header-sticky {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

img {
  -webkit-user-select: none;
  user-select: none;
}

dialog:not([open]) {
  pointer-events: none;
  opacity: 0;
  display: none;
}

.dots .dot {
  transform-origin: center;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.dots .dot--active {
  background-color: var(--color, black);
  width: 32px;
}

.stars-5-empty {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' fill='none'%3E%3Cpath fill='%23D9D9D9' d='m8 0 1.8 5.5h5.8L11 9l1.8 5.6L8 11l-4.7 3.4 1.8-5.6L.4 5.5h5.8L8 0Z'/%3E%3C/svg%3E");
}

.stars-5-full {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' fill='none'%3E%3Cpath fill='%23F06' d='m8 0 1.8 5.5h5.8L11 9l1.8 5.6L8 11l-4.7 3.4 1.8-5.6L.4 5.5h5.8L8 0Z'/%3E%3C/svg%3E");
}

.active--thumb {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.decor-stuck {
  top: 100vh;
}

html {
  scrollbar-gutter: stable;
}

body {
  --tw-color-yellow: #ff0;
  --tw-color-yellow-light: #ff03;
  --tw-color-purple: #f06;
  --tw-color-purple-light: #f063;
  --tw-color-green: #0f9;
  --tw-color-green-light: #0f93;
  --tw-color-blue: #0076e4;
  --tw-color-blue-dark: #1664ac;
  --tw-color-blue-light: #3cf3;
  --tw-color-gray-light: #f6f5f5;
  --tw-color-gray: #ccc;
  --tw-color-deepblue: #003;
}

.product-thumbs-slider-preinit .keen-slider__slide {
  flex-grow: 0;
  flex-shrink: 0;
  width: 33.3333% !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fold-scroll {
  --tw-translate-y: -10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .7s;
}

.rich-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.rich-content h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 1440px) {
  .rich-content h2 {
    font-size: 32px;
  }
}

.rich-content h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.rich-content .header-margin {
  margin: 0 0 1.25rem;
}

.rich-content ul {
  padding: .75rem 1.5rem;
  font-weight: 500;
  list-style-type: disc;
}

.rich-content .gray-card {
  background-color: #f6f5f5;
  border-width: 1px;
  border-color: #f6f5f5;
  border-radius: 7px;
  flex-direction: row;
  gap: .75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
}

.rich-content .gray-card h1, .rich-content .gray-card h2, .rich-content .gray-card h3 {
  margin-top: 0;
}

.rich-content .border-gray-card {
  border-width: 2px;
  border-color: #f6f5f5;
  border-radius: 7px;
  flex-direction: row;
  gap: .75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
}

.rich-content .border-gray-card h1, .rich-content .border-gray-card h2, .rich-content .border-gray-card h3 {
  margin-top: 0;
}

.rich-content .card-icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.rich-content .no-margin-header {
  margin: 0 0 .75rem;
}

.rich-content .product-ingredients {
  flex-direction: row;
  gap: .5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.rich-content .product-ingredients .packshot {
  flex-grow: 0;
  flex-shrink: 0;
}

.custom-captacha-styling img {
  margin-top: .5rem;
}

.custom-captacha-styling a {
  margin: .5rem;
  padding: .5rem;
  font-size: 14px;
  display: inline-block;
}

.half-banner-card {
  background-color: #f6f5f5;
  border-width: 1px;
  border-color: #f6f5f5;
  border-radius: 7px;
  flex-direction: row;
  gap: .75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
}

.half-banner-card .half-banner-card-content {
  width: 100%;
}

.half-banner-card .half-banner-card-banner {
  flex-grow: 0;
  flex-shrink: 0;
  width: 33.3333%;
}

.placeholder\:text-center::placeholder {
  text-align: center;
}

.placeholder\:text-\[\#5F6368\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(95 99 104 / var(--tw-text-opacity));
}

.placeholder\:text-\[\#888\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(136 136 136 / var(--tw-text-opacity));
}

.placeholder\:text-blue-dark::placeholder {
  color: #1664ac;
}

.placeholder\:text-white::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.backdrop\:bg-\[rgba\(255\,255\,255\,0\.2\)\]::backdrop {
  background-color: #fff3;
}

.backdrop\:backdrop-blur-\[5px\]::backdrop {
  --tw-backdrop-blur: blur(5px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-\[-120px\]:before {
  content: var(--tw-content);
  left: -120px;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:z-\[-1\]:before {
  content: var(--tw-content);
  z-index: -1;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:w-\[120px\]:before {
  content: var(--tw-content);
  width: 120px;
}

.before\:rounded-l-lg:before {
  content: var(--tw-content);
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.before\:bg-gray-light:before {
  content: var(--tw-content);
  background-color: #f6f5f5;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-\[\#888\]:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(136 136 136 / var(--tw-border-opacity));
}

.after\:bg-\[\#888\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(136 136 136 / var(--tw-bg-opacity));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:rounded-tl-\[10px\]:last-child {
  border-top-left-radius: 10px;
}

.checked\:bg-transparent:checked {
  background-color: #0000;
}

.checked\:bg-none:checked {
  background-image: none;
}

.empty\:hidden:empty {
  display: none;
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.hover\:border-blue:hover {
  border-color: #0071db;
}

.hover\:border-blue-dark:hover {
  border-color: #1664ac;
}

.hover\:border-gray-light:hover {
  border-color: #f6f5f5;
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:bg-blue:hover {
  background-color: #0071db;
}

.hover\:bg-blue-dark:hover {
  background-color: #1664ac;
}

.hover\:bg-gray-light:hover {
  background-color: #f6f5f5;
}

.hover\:bg-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 255, 153, var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-yellow:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 0, var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-gray:hover {
  color: #ccc;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.group\/shop:hover .group-hover\/shop\:flex {
  display: flex;
}

.group.header-sticky-change .group-\[\.header-sticky-change\]\:block, .group.is-highlighted .group-\[\.is-highlighted\]\:block {
  display: block;
}

.group.header-sticky-change .group-\[\.header-sticky-change\]\:hidden, .group.is-highlighted .group-\[\.is-highlighted\]\:hidden {
  display: none;
}

.peer:checked ~ .peer-checked\:border-blue-dark {
  border-color: #1664ac;
}

.peer:checked ~ .peer-checked\:bg-blue {
  background-color: #0071db;
}

.peer:checked ~ .peer-checked\:bg-blue-dark {
  background-color: #1664ac;
}

.peer:checked ~ .peer-checked\:bg-transparent {
  background-color: #0000;
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-blue:after {
  content: var(--tw-content);
  border-color: #0071db;
}

.peer:checked ~ .peer-checked\:after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:top-1\/2 {
  top: 50%;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:empty ~ .peer-empty\:flex {
  display: flex;
}

.peer:focus ~ .peer-focus\:top-\[-12px\] {
  top: -12px;
}

.peer:focus ~ .peer-focus\:-translate-y-0 {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.peer:focus ~ .peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.aria-\[hidden\=false\]\:mt-2[aria-hidden="false"] {
  margin-top: .5rem;
}

.aria-\[selected\=true\]\:hidden[aria-selected="true"] {
  display: none;
}

.aria-\[hidden\=true\]\:overflow-hidden[aria-hidden="true"] {
  overflow: hidden;
}

.aria-\[current\=\'true\'\]\:border-blue[aria-current="true"] {
  border-color: #0071db;
}

.aria-\[current\=\'true\'\]\:border-blue-dark[aria-current="true"] {
  border-color: #1664ac;
}

.aria-\[selected\=false\]\:border-white[aria-selected="false"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.aria-\[selected\=true\]\:border-transparent[aria-selected="true"] {
  border-color: #0000;
}

.aria-\[selected\=false\]\:bg-gray-light[aria-selected="false"] {
  background-color: #f6f5f5;
}

.group\/faq[aria-selected="true"] .group-aria-\[selected\=true\]\/faq\:rotate-180, .group\/mobile-shop[aria-selected="true"] .group-aria-\[selected\=true\]\/mobile-shop\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[success-state\=true\]\:visible[data-success-state="true"] {
  visibility: visible;
}

.data-\[savings\=\'0\'\]\:invisible[data-savings="0"] {
  visibility: hidden;
}

.data-\[is-expnd\=false\]\:max-h-0[data-is-expnd="false"] {
  max-height: 0;
}

.group[data-invalid-shipment="true"] .group-data-\[invalid-shipment\=true\]\:inline {
  display: inline;
}

.group[data-section-show="false"] .group-data-\[section-show\=false\]\:max-h-0 {
  max-height: 0;
}

.group[data-is-expanded="false"] .group-data-\[is-expanded\=false\]\:rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-invalid-shipment="true"] .group-data-\[invalid-shipment\=true\]\:rounded-xs {
  border-radius: 7px;
}

.group[data-invalid-shipment="true"] .group-data-\[invalid-shipment\=true\]\:border-purple {
  border-color: #f06;
}

.group[data-section-show="true"] .group-data-\[section-show\=true\]\:p-1 {
  padding: .25rem;
}

.group[data-invalid-shipment="true"] .group-data-\[invalid-shipment\=true\]\:text-purple {
  color: #f06;
}

@media (min-width: 480px) {
  .s\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .s\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .s\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .s\:block {
    display: block;
  }

  .s\:inline {
    display: inline;
  }

  .s\:flex {
    display: flex;
  }

  .s\:table-cell {
    display: table-cell;
  }

  .s\:h-\[44px\] {
    height: 44px;
  }

  .s\:w-1\/3 {
    width: 33.3333%;
  }

  .s\:w-2\/3 {
    width: 66.6667%;
  }

  .s\:w-\[10\%\] {
    width: 10%;
  }

  .s\:w-\[30\%\] {
    width: 30%;
  }

  .s\:w-\[44px\] {
    width: 44px;
  }

  .s\:max-w-\[420px\] {
    max-width: 420px;
  }

  .s\:max-w-\[460px\] {
    max-width: 460px;
  }

  .s\:max-w-\[480px\] {
    max-width: 480px;
  }

  .s\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .s\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .s\:flex-row {
    flex-direction: row;
  }

  .s\:gap-2 {
    gap: .5rem;
  }

  .s\:border-b-0 {
    border-bottom-width: 0;
  }

  .s\:border-l-0 {
    border-left-width: 0;
  }

  .s\:p-5 {
    padding: 1.25rem;
  }

  .s\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .s\:text-\[12px\] {
    font-size: 12px;
  }

  .s\:text-\[14px\] {
    font-size: 14px;
  }

  .s\:text-\[16px\] {
    font-size: 16px;
  }
}

@media (min-width: 576px) {
  .sm\:top-\[22px\] {
    top: 22px;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:h-\[110px\] {
    height: 110px;
  }

  .sm\:h-\[339px\] {
    height: 339px;
  }

  .sm\:h-\[374px\] {
    height: 374px;
  }

  .sm\:h-\[44px\] {
    height: 44px;
  }

  .sm\:\!max-h-\[810px\] {
    max-height: 810px !important;
  }

  .sm\:\!min-h-\[810px\] {
    min-height: 810px !important;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-\[10\%\] {
    width: 10%;
  }

  .sm\:w-\[133px\] {
    width: 133px;
  }

  .sm\:w-\[150px\] {
    width: 150px;
  }

  .sm\:w-\[20\%\] {
    width: 20%;
  }

  .sm\:w-\[30\%\] {
    width: 30%;
  }

  .sm\:w-\[306px\] {
    width: 306px;
  }

  .sm\:w-\[417px\] {
    width: 417px;
  }

  .sm\:w-\[44px\] {
    width: 44px;
  }

  .sm\:w-\[72px\] {
    width: 72px;
  }

  .sm\:max-w-\[520px\] {
    max-width: 520px;
  }

  .sm\:basis-1\/2 {
    flex-basis: 50%;
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-\[14px\] {
    font-size: 14px;
  }

  .sm\:text-\[16px\] {
    font-size: 16px;
  }

  .sm\:text-\[52px\] {
    font-size: 52px;
  }

  .sm\:leading-\[52px\] {
    line-height: 52px;
  }
}

@media (min-width: 768px) {
  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:left-\[-300px\] {
    left: -300px;
  }

  .md\:left-\[10px\] {
    left: 10px;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[unset\] {
    top: unset;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[127px\] {
    height: 127px;
  }

  .md\:h-\[450px\] {
    height: 450px;
  }

  .md\:h-\[502px\] {
    height: 502px;
  }

  .md\:h-\[96px\] {
    height: 96px;
  }

  .md\:h-\[calc\(100\%_-_24px\)\] {
    height: calc(100% - 24px);
  }

  .md\:\!max-h-\[645px\] {
    max-height: 645px !important;
  }

  .md\:max-h-\[610px\] {
    max-height: 610px;
  }

  .md\:\!min-h-\[645px\] {
    min-height: 645px !important;
  }

  .md\:min-h-\[546px\] {
    min-height: 546px;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-\[134px\] {
    width: 134px;
  }

  .md\:w-\[15\%\] {
    width: 15%;
  }

  .md\:w-\[450px\] {
    width: 450px;
  }

  .md\:w-\[45px\] {
    width: 45px;
  }

  .md\:w-\[502px\] {
    width: 502px;
  }

  .md\:w-\[96px\] {
    width: 96px;
  }

  .md\:max-w-\[1296px\] {
    max-width: 1296px;
  }

  .md\:max-w-\[135px\] {
    max-width: 135px;
  }

  .md\:max-w-\[200px\] {
    max-width: 200px;
  }

  .md\:max-w-\[300px\] {
    max-width: 300px;
  }

  .md\:max-w-\[405px\] {
    max-width: 405px;
  }

  .md\:max-w-\[425px\] {
    max-width: 425px;
  }

  .md\:max-w-\[610px\] {
    max-width: 610px;
  }

  .md\:max-w-\[620px\] {
    max-width: 620px;
  }

  .md\:max-w-\[720px\] {
    max-width: 720px;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:justify-self-auto {
    justify-self: auto;
  }

  .md\:rounded-lg {
    border-radius: 60px;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-\[14px\] {
    font-size: 14px;
  }

  .md\:text-\[16px\] {
    font-size: 16px;
  }

  .md\:text-\[44px\] {
    font-size: 44px;
  }

  .md\:text-\[48px\] {
    font-size: 48px;
  }

  .md\:text-\[64px\] {
    font-size: 64px;
  }

  .md\:text-\[78px\] {
    font-size: 78px;
  }

  .md\:leading-\[52px\] {
    line-height: 52px;
  }

  .md\:leading-\[72px\] {
    line-height: 72px;
  }
}

@media (min-width: 992px) {
  .lg\:left-\[-164px\] {
    left: -164px;
  }

  .lg\:right-\[215px\] {
    right: 215px;
  }

  .lg\:top-\[-105px\] {
    top: -105px;
  }

  .lg\:top-\[-38px\] {
    top: -38px;
  }

  .lg\:top-\[-90px\] {
    top: -90px;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[calc\(100\%_-_12px\)\] {
    height: calc(100% - 12px);
  }

  .lg\:\!max-h-\[630px\] {
    max-height: 630px !important;
  }

  .lg\:\!min-h-\[630px\] {
    min-height: 630px !important;
  }

  .lg\:min-h-\[667px\] {
    min-height: 667px;
  }

  .lg\:min-h-\[84px\] {
    min-height: 84px;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-\[169px\] {
    width: 169px;
  }

  .lg\:w-\[89px\] {
    width: 89px;
  }

  .lg\:max-w-\[1296px\] {
    max-width: 1296px;
  }

  .lg\:max-w-\[1440px\] {
    max-width: 1440px;
  }

  .lg\:max-w-\[422px\] {
    max-width: 422px;
  }

  .lg\:max-w-\[520px\] {
    max-width: 520px;
  }

  .lg\:max-w-\[526px\] {
    max-width: 526px;
  }

  .lg\:max-w-\[590px\] {
    max-width: 590px;
  }

  .lg\:max-w-\[625px\] {
    max-width: 625px;
  }

  .lg\:max-w-\[632px\] {
    max-width: 632px;
  }

  .lg\:max-w-\[700px\] {
    max-width: 700px;
  }

  .lg\:max-w-\[788px\] {
    max-width: 788px;
  }

  .lg\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:\!text-\[24px\] {
    font-size: 24px !important;
  }
}

@media (min-width: 1440px) {
  .xl\:bottom-\[-71px\] {
    bottom: -71px;
  }

  .xl\:left-\[-147px\] {
    left: -147px;
  }

  .xl\:left-\[-660px\] {
    left: -660px;
  }

  .xl\:left-\[14px\] {
    left: 14px;
  }

  .xl\:left-\[calc\(50\%_-_128px\)\] {
    left: calc(50% - 128px);
  }

  .xl\:right-\[-147px\] {
    right: -147px;
  }

  .xl\:right-\[-220px\] {
    right: -220px;
  }

  .xl\:top-\[-120px\] {
    top: -120px;
  }

  .xl\:top-\[-49px\] {
    top: -49px;
  }

  .xl\:top-\[31px\] {
    top: 31px;
  }

  .xl\:top-\[50px\] {
    top: 50px;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:order-4 {
    order: 4;
  }

  .xl\:order-5 {
    order: 5;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mr-auto {
    margin-right: auto;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-\[-60px\] {
    margin-top: -60px;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:h-\[152px\] {
    height: 152px;
  }

  .xl\:h-\[702px\] {
    height: 702px;
  }

  .xl\:min-h-\[1000px\] {
    min-height: 1000px;
  }

  .xl\:min-h-\[531px\] {
    min-height: 531px;
  }

  .xl\:min-h-\[746px\] {
    min-height: 746px;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-7\/12 {
    width: 58.3333%;
  }

  .xl\:w-\[161px\] {
    width: 161px;
  }

  .xl\:w-\[178px\] {
    width: 178px;
  }

  .xl\:w-\[702px\] {
    width: 702px;
  }

  .xl\:w-\[817px\] {
    width: 817px;
  }

  .xl\:min-w-\[636px\] {
    min-width: 636px;
  }

  .xl\:max-w-\[1296px\] {
    max-width: 1296px;
  }

  .xl\:max-w-\[1670px\] {
    max-width: 1670px;
  }

  .xl\:max-w-\[178px\] {
    max-width: 178px;
  }

  .xl\:max-w-\[1920px\] {
    max-width: 1920px;
  }

  .xl\:max-w-\[415px\] {
    max-width: 415px;
  }

  .xl\:max-w-\[416px\] {
    max-width: 416px;
  }

  .xl\:max-w-\[420px\] {
    max-width: 420px;
  }

  .xl\:max-w-\[480px\] {
    max-width: 480px;
  }

  .xl\:max-w-\[522px\] {
    max-width: 522px;
  }

  .xl\:max-w-\[526px\] {
    max-width: 526px;
  }

  .xl\:max-w-\[538px\] {
    max-width: 538px;
  }

  .xl\:max-w-\[612px\] {
    max-width: 612px;
  }

  .xl\:max-w-\[625px\] {
    max-width: 625px;
  }

  .xl\:max-w-\[630px\] {
    max-width: 630px;
  }

  .xl\:max-w-\[700px\] {
    max-width: 700px;
  }

  .xl\:max-w-\[746px\] {
    max-width: 746px;
  }

  .xl\:shrink-0 {
    flex-shrink: 0;
  }

  .xl\:grow-0 {
    flex-grow: 0;
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-\[max-content\,max-content\] {
    grid-template-columns: max-content max-content;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:gap-0 {
    gap: 0;
  }

  .xl\:gap-3 {
    gap: .75rem;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:rounded-lg {
    border-radius: 60px;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  }

  .xl\:bg-deepblue {
    background-color: #003;
  }

  .xl\:bg-\[url\(\'\/assets\/theme\/brand\/just-meal-compact-logotype-white\.svg\'\)\] {
    background-image: url("just-meal-compact-logotype-white.07248692.svg");
  }

  .xl\:object-\[unset\] {
    object-position: unset;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:pr-8 {
    padding-right: 2rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:\!text-\[32px\] {
    font-size: 32px !important;
  }

  .xl\:\!text-\[78px\] {
    font-size: 78px !important;
  }

  .xl\:text-\[64px\] {
    font-size: 64px;
  }

  .xl\:text-\[78px\] {
    font-size: 78px;
  }

  .xl\:leading-\[64px\] {
    line-height: 64px;
  }

  .xl\:leading-\[84px\] {
    line-height: 84px;
  }

  .xl\:before\:block:before {
    content: var(--tw-content);
    display: block;
  }

  .\32 xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .\32 xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .\32 xl\:h-\[calc\(100\%_-_48px\)\] {
    height: calc(100% - 48px);
  }

  .\32 xl\:\!max-h-\[835px\] {
    max-height: 835px !important;
  }

  .\32 xl\:\!min-h-\[835px\] {
    min-height: 835px !important;
  }

  .\32 xl\:max-w-full {
    max-width: 100%;
  }

  .\32 xl\:flex-row {
    flex-direction: row;
  }

  .\32 xl\:justify-end {
    justify-content: flex-end;
  }
}

@media (min-width: 1740px) {
  .\33 xl\:h-\[calc\(100\%_-_70px\)\] {
    height: calc(100% - 70px);
  }
}

.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

/*# sourceMappingURL=shop.26782077.css.map */
